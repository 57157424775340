import React from 'react'
import styled from 'styled-components'
import icArrowBottom from '../../static/icons/ic-arrow-bottom.png'

const SelectStyle = styled.select`
  border: 1px solid #dddddd;
  background-color: #fbfbfb;
  display: flex;
  color: #000;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: inherit;
  padding: 10px;

  &:not([multiple]) {
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position-x: 98%;
    background-position-y: 12px;
    background-repeat: no-repeat;
    background-image: url(${icArrowBottom});
    padding: 10px 10px;
    padding-right: 10px;
  }
`

const Select = props => {
  const { name, data, placeholder, onChange } = props
  return (
    <SelectStyle name={name} id="" onChange={onChange}>
      <option value="">{placeholder}</option>
      {data.map(item => (
        <option key={item.value} value={item.value}>
          {item.text}
        </option>
      ))}
    </SelectStyle>
  )
}

export default Select
