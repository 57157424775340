import React, { Fragment, useState } from 'react'
import Icon from '../../icons'
import styled from 'styled-components'
import swal from 'sweetalert'
const StepTwoStyle = styled.div`
  width: 352px;
  @media screen and (min-width: 300px) and (max-width: 620px) {
    width: 100%;
  }
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  height: 100%;
  .step-two-title {
    display: flex;
    color: #555555;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 12px;
  }
  label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .span-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  label:hover input ~ .span-icon {
    background-color: #ccc;
  }
  label input:checked ~ .span-icon {
    background-color: #2196f3;
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    content: '\f1ea';
  }
  label:after {
    content: '\f13a';
    position: absolute;
    display: none;
  }
  label input:checked ~ .span-icon:after {
    display: block;
  }
  label .span-icon:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
  .wrap-media-promotion {
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
    margin-bottom: 10px;
    margin-bottom: 10px;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 6px;
  }
  .span-text {
    width: 100%;
    display: flex;
    padding-top: 2px;
  }
`

const StepTwo = props => {
  const { onNextStep, step, onChange, regisData } = props
  // eslint-disable-next-line
  const [data, setData] = useState([
    {
      value: 'Browsing di internet',
      text: 'Browsing di internet'
    },
    {
      value: 'Media Sosial',
      text: 'Media Sosial'
    },
    {
      value: 'Staff Promosi',
      text: 'Staff promosi'
    },
    {
      value: 'Rekomendasi Teman',
      text: 'Rekomendasi Teman'
    },
    {
      value: 'Lainnya',
      text: 'Lainnya'
    }
  ])
  const handleSubmitButton = () => {
    return onNextStep(3)
  }
  if (step !== 2) {
    return false
  }
  const handleChoose = value => {
    data.map(item => {
      if (item.value === value) {
        item['checked'] = !item.checked
      }
      return item
    })
    // eslint-disable-next-line
    data.filter(item => {
      if (item.checked === true) onChange('media', item.value)
    })
  }

  const checkField = () => {
    const enter = regisData
    if (enter.media === '') {
      return swal('Warning', 'Field can not be empty', 'warning')
    } else {
      handleSubmitButton()
    }
  }

  return (
    <Fragment>
      <StepTwoStyle>
        <div className="step-two-title">Darimana anda mengetahui Halojasa?</div>
        {data.map((item, i) => {
          return (
            <div className="wrap-media-promotion" key={i}>
              <label name="media" onClick={() => handleChoose(item.value)}>
                <input type="radio" name="group-radio" />
                <i className="span-icon"></i>
                <span className="span-text">{item.text}</span>
              </label>
            </div>
          )
        })}
      </StepTwoStyle>
      <div className="modal-footer">
        <button onClick={checkField} type="button" className="smaller-hover">
          Selanjutnya <Icon size={12} name="chevronRight"></Icon>
        </button>
      </div>
    </Fragment>
  )
}

export default StepTwo
