import styled from 'styled-components'

export const StepOneStyles = styled.div`
  justify-content: space-between;
  height: 100%;
  display: flex;
  flex-direction: column;

  .container-form {
    width: '100%';
    display: 'block';
  }
`
