import React from 'react'

import { GroupStyles } from './group.styles'

const Group = props => {
  const { children } = props
  return <GroupStyles>{children}</GroupStyles>
}

export default Group
