import styled from 'styled-components'

export const LabelStyle = styled.label`
  width: 100%;
  display: flex;
  color: #555555;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 10px;
  align-items: center;

  span {
    font-size: 8px;
    border: 1px solid #ff7400;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    margin-left: 10px;
    width: 12px;
    height: 12px;
    color: #ff7400;
  }
  .helper {
    position: relative;
  }
  .box {
    position: absolute;
    width: 171px;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    left: 39px;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
    color: #555555;
    font-family: 'Open Sans';
    font-size: 12px;
    line-height: 17px;
    padding: 12px;
    border-radius: 5px;
  }

  .arrow-left {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #fff;
    position: absolute;
    top: 0px;
    left: 30px;
  }
  .arrow-left-background {
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #eeeeee;
    position: absolute;
    top: -2px;
    left: 29px;
    z-index: -1;
  }
`
