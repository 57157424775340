import styled from 'styled-components'

export const InputStyles = styled.input`
  background-color: transparent;
  display: flex;
  height: inherit;
  width: 100%;
  color: #000;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: inherit;
  border: 0;
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
  outline: none;
`
export const SelectStyles = styled.select`
  border: 1px solid transparent;
  background-color: transparent;
  display: flex;
  height: inherit;
  color: #000;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: inherit;
  position: relative;
  top: 0;
  padding: 10px 0;
  &:not([multiple]) {
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(icArrowBottom);
    padding: 0;
  }
`

export const InputPhoneNumber = styled.div`
  border: 1px solid #dddddd;
  background-color: #fbfbfb;
  display: flex;
  color: #000;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
  padding: 10px;
  height: 100%;
  align-items: center;
`
