/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Icon from '../../icons'
import Input from '../../form/input'
import Select from '../../form/select'
import Label from '../../form/label'
import Group from '../../form/group'
import SelectInputPhone from '../../form/input-phone-number'
import styled from 'styled-components'
import { getProvince, getCity } from '../../../services/register'

const StepOneStyles = styled.div`
  justify-content: space-between;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const StepOne = props => {
  const { onNextStep, step, onChange, regisData } = props
  const [provinces, setProvince] = useState([])
  const [cities, setCity] = useState([])

  useEffect(() => {
    getProvinceData()
  }, [])

  const getProvinceData = () => {
    getProvince()
      .then(res => {
        const data = res.data.result.map(e => {
          return {
            value: e.id,
            text: e.province_name
          }
        })
        setProvince(data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const setCityData = id => {
    getCity(id)
      .then(res => {
        const data = res.data.result.map(e => {
          return {
            value: e.id,
            text: e.city_name
          }
        })
        setCity(data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleSubmitButton = () => {
    return onNextStep(2)
  }
  if (step !== 1) {
    return false
  }

  const checkField = () => {
    const enter = regisData
    const pattern_mail = /\S+@\S+\.\S+/
    const pattern_phone = /\d{9,}/
    const pattern_vendorName = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/

    if (
      enter.vendorName == '' ||
      enter.cityId == '' ||
      enter.phone == '' ||
      enter.mail == ''
    ) {
      return swal('Warning', 'Please, check your input again', 'warning')
    } else {
      if (pattern_phone.test(enter.phone) == false)
        return swal('Warning', 'Phone number is invalid', 'warning')
      if (pattern_mail.test(enter.mail) == false)
        return swal('Warning', 'Email is invalid', 'warning')
      if (pattern_vendorName.test(enter.vendorName) == false)
        return swal('Warning', 'Vendor name is invalid', 'warning')
      handleSubmitButton()
    }
  }

  return (
    <StepOneStyles>
      <div className="modal-form">
        <form
          id="register-individu"
          action=""
          style={{ padding: '30px 0', width: '100%', display: 'block' }}
        >
          <Group>
            <Label
              withStep={
                'Masukkan nama lengkap anda dengan benar, untuk keperluan proses verifikasi'
              }
              htmlFor="vendorName"
            >
              Nama Lengkap
            </Label>
            <Input
              placeholder="Masukkan nama Lengkap Anda"
              name="vendorName"
              type="text"
              onChange={onChange}
            />
          </Group>
          <Group>
            <Label htmlFor="province">Kategori</Label>
            <Select
              placeholder="Pilih Provinsi"
              name="provinceId"
              type="text"
              onChange={e => {
                setCityData(e.target.value)
              }}
              data={provinces}
            ></Select>
          </Group>
          <Group>
            <Label htmlFor="vendorName">Kota Domisili</Label>
            <Select
              placeholder="Pilih kota domisili"
              name="cityId"
              type="text"
              disabled={cities.length === 0}
              onChange={onChange}
              data={cities}
            ></Select>
          </Group>
          <Group>
            <Label
              withStep={
                'Masukkan nomor telepon anda dengan benar, untuk keperluan proses verifikasi'
              }
              htmlFor="handphone"
            >
              No. Handphone
            </Label>
            <SelectInputPhone
              placeholderInput="812 90 1234 90"
              name="phone"
              onChange={onChange}
            ></SelectInputPhone>
          </Group>
          <Group>
            <Label
              withStep={
                'Masukkan email anda dengan benar, untuk keperluan proses verifikasi'
              }
              htmlFor="mail"
            >
              Email
            </Label>
            <Input
              placeholder="eg.  foo@bar.com"
              name="mail"
              type="email"
              onChange={onChange}
            ></Input>
          </Group>
        </form>
      </div>
      <div className="modal-footer">
        <button
          onClick={checkField}
          type="button"
          className="smaller-hover"
          // disabled={disabled}
        >
          Selanjutnya <Icon size={12} name="chevronRight"></Icon>
        </button>
      </div>
    </StepOneStyles>
  )
}

export default StepOne
