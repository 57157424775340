import React from 'react'

import { InputStyles } from './input.styles'

const Input = props => {
  const { type, name, placeholder, onChange } = props
  return (
    <InputStyles
      placeholder={placeholder}
      id={name}
      name={name}
      type={type}
      onChange={onChange}
    />
  )
}

export default Input
