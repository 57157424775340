import React, { useState, useRef, useEffect } from 'react'

import { LabelStyle } from './label.styles'

const Label = props => {
  const { children, htmlFor, withStep } = props
  const elementHelper = useRef(null)
  const [isShow, setShow] = useState(false)
  const handleClickOutside = event => {
    if (
      elementHelper.current &&
      !elementHelper.current.contains(event.target) &&
      isShow
    ) {
      setShow(false)
    }
  }
  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
  const handleClickHelper = () => {
    setShow(!isShow)
  }
  return (
    <LabelStyle htmlFor={htmlFor}>
      {children}{' '}
      {withStep ? (
        <div ref={elementHelper} className="helper">
          <span onClick={handleClickHelper}>?</span>
          {isShow ? (
            <div>
              <div className="box">{withStep}</div>
              <div className="arrow-left"></div>
              <div className="arrow-left-background"></div>
            </div>
          ) : (
            false
          )}
        </div>
      ) : (
        false
      )}
    </LabelStyle>
  )
}

export default Label
