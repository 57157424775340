import styled from 'styled-components'

export const InputStyles = styled.input`
  border: 1px solid #dddddd;
  background-color: #fbfbfb;
  display: flex;
  color: #000;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
  padding: 10px;
`
