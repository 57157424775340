import React from 'react'

import {
  InputStyles,
  SelectStyles,
  InputPhoneNumber
} from './input-phone-number.styles'

const Input = props => {
  const { type, placeholderInput, name, onChange } = props
  return (
    <InputPhoneNumber>
      <SelectStyles disabled>
        <option value="">+62</option>
      </SelectStyles>
      <InputStyles
        placeholder={placeholderInput}
        type={type}
        name={name}
        onChange={onChange}
      />
    </InputPhoneNumber>
  )
}

export default Input
