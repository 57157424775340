import styled from 'styled-components'

export default styled.header`
  .header-responsive-head {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: ${props => props.background || '#ff7b00'};
    height: 55px;
    padding: 13px 0 0 15px;
    box-sizing: border-box;
    z-index: 1020;

    img {
      width: 150px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .header-reponsive-custom {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #0000004a;
    z-index: 1024;
    transition: all 0.4s ease-in-out;
    left: -100%;

    &.active-header-responsive {
      left: 0;
    }

    .close-responsive-header {
      background: none;
      border: 0;
      position: absolute;
      top: 15px;
      left: 260px;

      svg {
        width: 40px;
      }
    }

    .header-responsive-content-wrapper {
      box-sizing: border-box;
      background: ${props => props.background || '#ff7b00'};
      width: 250px;
      height: 100%;
      max-height: 100%;
      overflow-y: auto;
      padding: 24px;
      position: relative;
      &:before {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #717171;
        z-index: -1;
        position: absolute;
      }

      .halo-jasa-icon-responsive {
        width: 146px;
      }

      ul.main-responsive-header-menu {
        margin: 40px 0 0;
        padding: 0;
        list-style: none;

        li {
          font-size: 18px;
          color: #ffffff;
          padding-bottom: 24px;
          border-bottom: solid 1px #ffffff;
          line-height: 1;
          margin-bottom: 24px;
          position: relative;

          .arrow-menu {
            position: absolute;
            right: 0;
            top: -3px;
            transition: ease-in-out 0.3s;
          }

          &.active-expand-responsive-menu {
            .arrow-menu {
              transform: rotate(180deg);
            }

            ul {
              overflow: visible !important;
              max-height: 200px !important;
            }
          }

          &:nth-last-child(1) {
            border: 0;
          }

          a {
            color: #ffffff;
            text-decoration: none;
          }

          ul {
            padding: 0;
            margin: 0;
            list-style: none;
            overflow: hidden;
            max-height: 0;
            transition: ease-in-out 0.3s;

            li {
              font-size: 16px;
              border: 0;
              padding: 0 0 0 15px;
              padding-top: 0px;
              padding-right: 0px;
              padding-bottom: 0px;
              padding-left: 15px;
              margin: 12px 0 0;
            }
          }
        }
      }

      .header-responsive-header-wrapper {
        button {
          border-radius: 8px;
          width: 100%;
          padding: 16px 0;
          line-height: 1;
          text-align: center;
          font-size: 15px;
          outline: 0;

          &.white-button {
            background: #ffffff;
            border: solid 1px #ffffff;
            color: #ff7b00;
            margin-bottom: 12px;
          }

          &.orange-button {
            background: transparent;
            border: solid 1px #ffffff;
            color: #ffffff;
            margin-top: 12px;
          }
        }
      }
    }
  }
`
