import styled from 'styled-components'

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  .modal-step {
    width: 260px;
    margin: 0 auto 50px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: auto;
      height: 2px;
      z-index: -1;
      background: #ddd;
      top: 8px;
      left: 40px;
      right: 40px;
    }
    &.step {
      &-1,
      &-2,
      &-3,
      &-4 {
        &:after {
          transition: all 1s ease-in;
          content: '';
          position: absolute;
          height: 2px;
          z-index: -1;
          background: linear-gradient(270deg, #ff9700 0%, #ff7400 100%);
          top: 8px;
          left: 40px;
          width: 0;
        }
      }
      &-2 {
        &:after {
          width: 30%;
        }
      }
      &-3 {
        &:after {
          width: 60%;
        }
      }
      &-4 {
        &:after {
          width: 85%;
          right: 40px;
        }
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .number {
        background: #ddd;
        text-align: center;
        border-radius: 50%;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: bold;
        height: 18px;
        width: 18px;
        transition: background 1.5s ease-in;
      }
      .text {
        width: 100%;
        text-align: center;
        margin-top: 10px;
        font-family: 'Open Sans';
        font-size: 14px;

        @media screen and (min-width: 300px) and (max-width: 620px) {
          display: none;
        }
      }
      &.active {
        .number {
          transition: background 1.5s ease-in;
          background: linear-gradient(270deg, #ff9700 0%, #ff7400 100%);
        }
        .text {
          transition: background 1.5s ease-in;
          color: #ff7400;
        }
      }
    }
  }

  .modal-form {
    width: 352px;
    @media screen and (min-width: 300px) and (max-width: 620px) {
      width: 100%;
    }
    display: flex;
    margin: 0 auto;
  }
  .modal-footer {
    width: 352px;
    display: flex;
    margin: 0 auto;
    align-items: flex-end;
    justify-content: flex-end;

    @media screen and (min-width: 300px) and (max-width: 620px) {
      width: 100%;
    }

    > button {
      border-radius: 22.5px;
      margin-top: 30px;
      cursor: pointer;
      background: linear-gradient(270deg, #ff9700 0%, #ff7400 100%);
      padding: 13px 56px 13px 46px;
      display: flex;
      justify-content: space-around;
      font-size: 14px;
      color: white;
      outline: none;
      border: unset;
      align-items: center;
      color: #ffffff;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      position: relative;
      > i {
        margin-left: 10px;
        position: absolute;
        right: 20px;
        z-index: 1;
        top: 18px;
        bottom: 0;
      }
    }
  }

  .b2b-step-2 {
    position: relative;
    right: -25px;
  }

  @media screen and (min-width: 300px) and (max-width: 620px) {
    .b2b-step-2 {
      right: 22px;
    }

    .b2b-step-1 {
      position: relative;
      left: 22px;
    }
  }
`
