import Facebook from '../../static/icons/social/facebook-grey.png'
import Instagram from '../../static/icons/social/instagram-grey.png'
import Linkedin from '../../static/icons/social/linkedin-grey.png'
import Twitter from '../../static/icons/social/twitter-grey.png'
import Youtube from '../../static/icons/social/youtube-grey.png'
import Pinterest from '../../static/icons/social/pinterest-grey.png'
import chevronRight from '../../static/icons/ic-chevron-right.svg'
import close from '../../static/icons/ic-close.svg'
import styled from 'styled-components'

const iconList = {
  facebook: Facebook,
  instagram: Instagram,
  linkedin: Linkedin,
  twitter: Twitter,
  youtube: Youtube,
  pinterest: Pinterest,
  chevronRight: chevronRight,
  close: close
}

const Icon = styled.i`
  background-image: url(${props => iconList[props.name]});
  width: ${props => props.size || 29}px;
  height: ${props => props.size || 29}px;
  display: inline-block;
  background-repeat: no-repeat;
`

export default Icon
