import React, { useState, useCallback } from 'react'

import Modal from '../../modal'
import StepOne from './step-one'
import StepTwo from './step-two'
import StepThree from './step-three'
import StepFour from './step-four'
import styled from 'styled-components'
import useForm from './useForm'

const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  .modal-step {
    width: 569px;
    @media screen and (min-width: 300px) and (max-width: 620px) {
      width: 220px;
    }
    margin: 0 auto 50px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: auto;
      height: 2px;
      z-index: -1;
      background: #ddd;
      top: 8px;
      left: 40px;
      right: 40px;
    }
    &.step {
      &-1,
      &-2,
      &-3,
      &-4 {
        &:after {
          transition: all 1s ease-in;
          content: '';
          position: absolute;
          height: 2px;
          z-index: -1;
          background: linear-gradient(270deg, #ff9700 0%, #ff7400 100%);
          top: 8px;
          left: 40px;
          width: 0;
        }
      }
      &-2 {
        &:after {
          width: 30%;
        }
      }
      &-3 {
        &:after {
          width: 60%;
        }
      }
      &-4 {
        &:after {
          width: 85%;
          right: 40px;
        }
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .number {
        background: #ddd;
        text-align: center;
        border-radius: 50%;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: bold;
        height: 18px;
        width: 18px;
        transition: background 1.5s ease-in;
      }
      .text {
        width: 100%;
        text-align: center;
        margin-top: 10px;
        font-family: 'Open Sans';
        font-size: 14px;

        @media screen and (min-width: 300px) and (max-width: 620px) {
          display: none;
        }
      }
      &.active {
        .number {
          transition: background 1.5s ease-in;
          background: linear-gradient(270deg, #ff9700 0%, #ff7400 100%);
        }
        .text {
          transition: background 1.5s ease-in;
          color: #ff7400;
        }
      }
    }
  }

  .modal-form {
    width: 352px;
    @media screen and (min-width: 300px) and (max-width: 620px) {
      width: 100%;
    }
    display: flex;
    margin: 0 auto;
  }
  .modal-footer {
    width: 352px;
    @media screen and (min-width: 300px) and (max-width: 620px) {
      width: 100%;
    }
    display: flex;
    margin: 0 auto;
    align-items: flex-end;
    justify-content: flex-end;

    > button {
      border-radius: 22.5px;
      margin-top: 30px;
      cursor: pointer;
      background: linear-gradient(270deg, #ff9700 0%, #ff7400 100%);
      padding: 13px 56px 13px 46px;
      display: flex;
      justify-content: space-around;
      font-size: 14px;
      color: white;
      outline: none;
      border: unset;
      align-items: center;
      color: #ffffff;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      position: relative;
      > i {
        margin-left: 10px;
        position: absolute;
        right: 20px;
        z-index: 1;
        top: 18px;
        bottom: 0;
      }
    }
    > button:disabled {
      cursor: auto;
      text-decoration: none;
      margin-right: 15px;
      color: #fff;
      padding: 13px 32px;
      border-radius: 22.5px;
      background: #dad9d9;
      border: none;
      text-align: center;
      width: 100%;
    }
  }

  @media screen and (min-width: 300px) and (max-width: 620px) {
    .individual-step-1 {
      position: relative;
      left: 22px;
    }

    .individual-step-2 {
      position: relative;
      left: 10px;
    }

    .individual-step-3 {
      position: relative;
      right: 7px;
    }

    .individual-step-4 {
      position: relative;
      right: 22px;
    }

    .modal-step.step-2::after {
      width: 25%;
    }

    .modal-step.step-3::after {
      width: 40%;
    }

    .modal-step.step-4::after {
      width: 70%;
    }
  }
`

const Register = props => {
  const { active, handleOnCloseModal } = props
  const [step, setStep] = useState(1)
  const onNextStep = useCallback(stepParams => {
    setStep(stepParams)
  }, [])

  const handleCloseModal = useCallback(() => {
    handleOnCloseModal()
  }, [handleOnCloseModal])

  const {
    regisData,
    onChange,
    onchangeCheckbox,
    registerNow,
    uploadIdCard
  } = useForm(onNextStep)

  return (
    <Modal
      onCloseModal={handleCloseModal}
      active={active}
      title="FORM PENDAFTARAN INDIVIDU"
      classCSS="modal-header--close smaller-hover individu-register-close"
    >
      <ModalContent>
        <div className={`modal-step step-${step}`}>
          <div
            className={`modal-step-list ${
              step >= 1 ? 'active' : ''
              } individual-step-1`}
          >
            <div className="number">1</div>
            <div className="text">Informasi Diri</div>
          </div>
          <div
            className={`modal-step-list ${
              step >= 2 ? 'active' : ''
              } individual-step-2`}
          >
            <div className="number">2</div>
            <div className="text">Informasi Tambahan</div>
          </div>
          <div
            className={`modal-step-list ${
              step >= 3 ? 'active' : ''
              } individual-step-3`}
          >
            <div className="number">3</div>
            <div className="text">Jenis Layanan Jasa</div>
          </div>
          <div
            className={`modal-step-list ${
              step >= 4 ? 'active' : ''
              } individual-step-4`}
          >
            <div className="number">4</div>
            <div className="text">Identitas Diri</div>
          </div>
        </div>
        <StepOne
          step={step}
          onNextStep={onNextStep}
          onChange={onChange}
          regisData={regisData}
        ></StepOne>
        <StepTwo
          step={step}
          onNextStep={onNextStep}
          onChange={onchangeCheckbox}
          regisData={regisData}
        ></StepTwo>
        <StepThree
          step={step}
          onNextStep={onNextStep}
          onChange={onchangeCheckbox}
          regisData={regisData}
        ></StepThree>
        <StepFour
          step={step}
          onNextStep={onNextStep}
          registerNow={registerNow}
          onChange={onchangeCheckbox}
          upload={uploadIdCard}
          regisData={regisData}
        ></StepFour>
      </ModalContent>
    </Modal>
  )
}

export default Register
