import { useState } from 'react'
import { signUpIndividual, uploadId } from './../../../services/register'
import swal from 'sweetalert'

const useForm = () => {
  const akusisiCode = window.location.pathname.split('/')[2]

  let regex = new RegExp('^[0-9]+$')

  const [regisData, setData] = useState({
    cityId: '',
    serviceId: '',
    vendorName: '',
    phone: '',
    code: regex.test(akusisiCode) ? akusisiCode : '0',
    mail: '',
    media: '',
    image: ''
  })

  const onChange = e => {
    e.persist()
    setData({ ...regisData, [e.target.name]: e.target.value })
  }
  const onchangeCheckbox = (name, value) => {
    setData({ ...regisData, [name]: value })
  }

  const registerNow = () => {
    signUpIndividual(regisData)
      .then(response => {
        if (response) {
          // swal('Success', 'Success to register vendor', 'success')
          swal({
            title: 'Success',
            text: 'Success to register vendor',
            type: 'success',
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ok',
            cancelButtonText: 'CANCEL',
            closeOnConfirm: true,
            closeOnCancel: false
          }).then(() => {
            window.location = '/'
          })
        }
      })
      .catch(err => {
        if (err.message === 'your phone number already register') {
          swal({
            title: 'Register failed',
            text: 'Your phone number already register',
            type: 'warning',
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ok',
            cancelButtonText: 'CANCEL',
            closeOnConfirm: true,
            closeOnCancel: false
          }).then(() => {
            window.location.reload()
          })
        } else if (err.message === 'Request failed with status code 429') {
          swal({
            title: 'Failed',
            text: 'Sorry, you made too many requests',
            type: 'warning',
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ok',
            cancelButtonText: 'CANCEL',
            closeOnConfirm: true,
            closeOnCancel: false
          }).then(() => {
            window.location.href = 'https://halojasa.com/'
          })
        } else {
          swal({
            title: 'Register failed',
            text: 'Please try again',
            type: 'warning',
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ok',
            cancelButtonText: 'CANCEL',
            closeOnConfirm: true,
            closeOnCancel: false
          }).then(() => {
            window.location.reload()
          })
        }
      })
  }
  const uploadIdCard = async file => {
    uploadId(file)
      .then(res => {
        let imageName = res.data.result.split('/')[4]
        setData({ ...regisData, image: imageName })
      })
      .catch(err => {
        if (err.message === 'Request failed with status code 429') {
          swal({
            title: 'Failed',
            text: 'Sorry, you made too many requests',
            type: 'warning',
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Ok',
            cancelButtonText: 'CANCEL',
            closeOnConfirm: true,
            closeOnCancel: false
          }).then(() => {
            window.location.href = 'https://halojasa.com/'
          })
        } else {
          swal(
            'Failed upload image ',
            'Please try again or contact us',
            'warning'
          )
        }
      })
  }

  return {
    regisData,
    setData,
    onChange,
    onchangeCheckbox,
    registerNow,
    uploadIdCard
  }
}
export default useForm
