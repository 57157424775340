/* eslint-disable */
import React, { useState, useCallback } from 'react'

import Modal from '../../modal'
import StepOne from './step-one'
import StepTwo from './step-two'
import { ModalContent } from './index.styles'

const Register = props => {
  const { active, handleOnCloseModal } = props
  const [step, setStep] = useState(1)

  const onNextStep = useCallback(stepParams => {
    setStep(stepParams)
  }, [])
  const handleCloseModal = useCallback(() => {
    handleOnCloseModal()
  }, [handleOnCloseModal])

  return (
    <Modal
      onCloseModal={handleCloseModal}
      active={active}
      title="FORM KERJASAMA MITRA B2B"
      classCSS="modal-header--close smaller-hover b2b-register-close"
    >
      <ModalContent>
        <div className={`modal-step step-${step}`}>
          <div
            className={`modal-step-list ${
              step >= 1 ? 'active' : ''
              } b2b-step-1`}
          >
            <div className="number">1</div>
            <div className="text">Informasi Diri</div>
          </div>
          <div
            className={`modal-step-list ${
              step >= 2 ? 'active' : ''
              } b2b-step-2`}
          >
            <div className="number">2</div>
            <div className="text">Informasi Perusahaan</div>
          </div>
        </div>
        <StepOne step={step} onNextStep={onNextStep}></StepOne>
        <StepTwo step={step} onNextStep={onNextStep}></StepTwo>
      </ModalContent>
    </Modal>
  )
}

export default Register
