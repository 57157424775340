import api from './Api'

export const signUpIndividual = data => {
  return api
    .post(
      'landingpage/registerVendor/individual',
      {
        cityId: data.cityId,
        serviceId: data.serviceId,
        vendorName: data.vendorName,
        phone: data.phone,
        mail: data.mail,
        media: data.media,
        image: data.image,
        code: data.code
      },
      {
        headers: {
          'Content-Type': 'application/json'
          // origin: 'halojasa-backoffice'
        }
      }
    )
    .then(res => {
      return Promise.resolve(res)
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const uploadId = image => {
  return api
    .post('/landingpage/registerVendor/upload/idcard', image, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
        // origin: 'halojasa-backoffice'
      }
    })
    .then(res => {
      return Promise.resolve(res)
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const getProvince = () => {
  return api
    .get('/landingpage/province', {
      headers: {
        'Content-Type': 'application/json'
        // origin: 'halojasa-backoffice'
      }
    })
    .then(res => {
      return Promise.resolve(res)
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const getCity = id => {
  return api
    .get(`/landingpage/city/${id}`, {
      headers: {
        'Content-Type': 'application/json'
        // origin: 'halojasa-backoffice'
      }
    })
    .then(res => {
      return Promise.resolve(res)
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
