/* eslint-disable */
import React from 'react'
import Icon from '../../icons'
import Input from '../../form/input'
import Label from '../../form/label'
import Group from '../../form/group'
import SelectInputPhone from '../../form/input-phone-number'
import { StepOneStyles } from './step-one.styles'

const StepOne = props => {
  const { onNextStep, step } = props

  const handleSubmitButton = () => {
    return onNextStep(2)
  }
  if (step !== 1) {
    return false
  }
  return (
    <StepOneStyles>
      <div className="modal-form">
        <form
          id="register-vendor-one"
          action=""
          className="container-form"
        >
          <Group>
            <Label
              withStep={
                'Masukkan nama lengkap anda dengan benar, untuk keperluan proses verifikasi'
              }
              htmlFor="vendorName"
            >
              Nama Lengkap
            </Label>
            <Input
              placeholder="eg. Ahmad Widodo"
              name="vendorName"
              type="text"
            ></Input>
          </Group>
          <Group>
            <Label
              withStep={
                'Masukkan nomor telepon anda dengan benar, untuk keperluan proses verifikasi'
              }
              htmlFor="handphone"
            >
              No. Handphone
            </Label>
            <SelectInputPhone placeholderInput="812 90 1999234 90"></SelectInputPhone>
          </Group>
          <Group>
            <Label
              withStep={
                'Masukkan email anda dengan benar, untuk keperluan proses verifikasi'
              }
              htmlFor="vendorName"
            >
              Email
            </Label>
            <Input
              placeholder="eg.  foo@bar.com"
              name="vendorName"
              type="email"
            ></Input>
          </Group>
        </form>
      </div>
      <div className="modal-footer">
        <button className="vendor-next-form" onClick={handleSubmitButton} type="button">
          Selanjutnya <Icon size={12} name="chevronRight"></Icon>
        </button>
      </div>
    </StepOneStyles>
  )
}

export default StepOne
